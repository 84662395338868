<template>
  <div class="realEstatePanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('New company') }}
          </h4>
        </div>


        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Company Name')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Bank Name')" rules="required">
                <b-form-input id="realEstate-name" v-model="bank.name" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Address')" label-for="bank-company">
              <b-form-input id="realEstate-sell-price" v-model="bank.address"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Zip')" label-for="bank-company">
              <b-form-input id="realEstate-sell-price" v-model="bank.zip"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('City')" label-for="bank-company">
              <b-form-input id="realEstate-sell-price" v-model="bank.city"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Country')" label-for="bank-company">
              <b-form-input id="realEstate-sell-price" v-model="bank.country"
              />
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Contact Name')" label-for="bank-company">
              <b-form-input id="realEstate-sell-price" v-model="bank.contactName"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Contact Phone')" label-for="bank-company">
              <b-form-input id="realEstate-sell-price" v-model="bank.contactPhone"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Contact Email')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Contact Email')" rules="required">
                <b-form-input id="realEstate-name" v-model="bank.contactEmail" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Branch -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Branch')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Branch')" rules="required">
                <v-select

                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="bank.buid"
                    :options="allBranches"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"
                    :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


        </b-row>
      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'bankPanel',
  components: {},
  data() {
    return {
      loaded: false,
      error: null,
      bankId: 0,
      bank: {
        company: '',
      },

      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: Number,
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    }
  },
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {

    this.onLoad()
    this.$root.$on('crm::submitBank', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitBank', this.listeners.submit)
  },
  methods: {
    ...mapActions('bank', {
      _register: 'addBank',
    }),
    onLoad() {
      this.loaded = true
    }
    ,
    createBank() {
      let $this = this
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._register(this.bank)
            .then(
                (id) => {

                  $this.bankId = id
                  $this.$emit('submitted', {
                    status: true,
                    server: id,
                    id

                  })
                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
            .finally(() => {
              console.log('id: ', $this.bankId)
              this.$router.push('/bank/edit/' + $this.bankId)

            })
      })
    },

    validate(field) {
      return this.$v.bank[field] != null && this.$v.bank[field].$error === true
    }
    ,
    submit() {
      return this.createBank()
    }

  },

}
</script>


